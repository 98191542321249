import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import iconAdv1 from "../images/GhostKitschen/icon-adv-1.png";
import iconAdv2 from "../images/GhostKitschen/icon-adv-2.png";
import iconAdv3 from "../images/GhostKitschen/icon-adv-3.png";
import ContactForm from "../components/contactForm/form";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Integrations from "../components/integrations/integrations";
import News from "../components/news/news";
import Seo from "../components/seo";
import SingleDevice from "../components/singleDevice/singleDevice";
import Testimonials from "../components/testimonials/testimonials";
import Top from "../components/top/top";

import testimonialsState from "./../utils/testimonials";

// markup
const GhostKitchenPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 1,
    scrollToForm: scrollToForm,
    title: "The Fastest Way To Streamline Your Ghost Kitchen",
    subtitle: `Orders.co offers an expansive integration system that allows ghost kitchens to consolidate
            all online ordering platforms into one place. Save time and eliminate stress by having one unified
            ordering list to access and fulfill all incoming orders.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="ghost kitchen restaurant"
        src="../images/GhostKitschen/ghost-kitchen.png"
        className="mw-100"
      />
    ),
  };

  const features = [
    {
      bg: "#DEFFEE",
      icon: iconAdv1,
      title: "One Device",
      description: `Free staff from the stress of managing multiple tablets with Orders.co consolidation.
                All online ordering platforms in one user-friendly device.`,
    },
    {
      bg: "#F1F7FF",
      icon: iconAdv2,
      title: "One Order List",
      description: `Make servicing hours a breeze with all brands, concepts, and platforms in one unified ordering list.`,
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Reporting",
      description: `Use tools like top selling items, sales by provider,
                and summary reports to make data-driven decision and optimize all online ordering channels.`,
    },
  ];

  const singleDeviceProps = {
    title: `Easily manage your restaurant's online orders with a single device`,
    image: (
      <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Restaurant Menu Management"
          src="../images/menu-management_tablet.png"
          className="mw-100"
        />
      </>
    ),
    scrollToForm: scrollToForm,
  };

  const testimonialsProps = {
    layout: 1,
    items: [
      // testimonialsState.silver_chicken_and_seafood,
      testimonialsState.dors_craft_beer_and_kitchen,
      testimonialsState.c_viche,
      testimonialsState.jonahs_kabob,
    ],
    title: "Hear From Our Customers",
    tag: "Testimonials",
  };

  const imageTextProps1 = {
    layout: "equal-big",
    title: "Save Time With An Easy-to-Build Menu",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Orders.co’s Master Menu Management system makes it easy to craft the perfect menu for all
            your ordering platforms in a fraction of the time. Time is money and we save you both.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="ordering platform"
        src="../images/GhostKitschen/menu-management.png"
      />
    ),
  };
  const imageTextProps2 = {
    layout: "equal-big",
    title: "15 Minute Onboarding",
    titleStyle: "smaller",
    imagePosition: "left",
    description: `Schedule a quick 15 minute phone call to give our integration team the necessary information,
            then sit back and let them take over. We provide regular updates on the status of your onboarding and
            will do all the heavy lifting for you.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="ordering webiste onboarding"
        src="../images/GhostKitschen/Ordering website.png"
      />
    ),
  };
  const imageTextProps3 = {
    layout: "equal-big",
    title: "Master Menu Management",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Multiple brands across 4+ providers makes menu updates a huge issue.
            Master menu makes it easy, make updates once for each brand and done.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="Master Menu Management"
        src="../images/GhostKitschen/Master-Menu-Management.png"
      />
    ),
  };

  const questions = [
    {
      question: `How long does it take to set up Orders.co?`,
      answer: `Orders.co’s integration and setup takes around 1-2 weeks, depending on the current workload.
                For a more detailed estimate, contact a sales representative.`,
    },
    {
      question: `How does Orders.co integration benefit my Ghost Kitchen?`,
      answer: `Ghost Kitchens are a unique new style of restaurant that primarily generate revenue through
                delivery-only channels. In doing so, many of these businesses will have multiple Brands across
                a variety of delivery platforms. This results in an abundance of tablets needed to manage menus
                and service orders. Orders.co consolidates all of this into a single device.
                Generating one uniform order list for all connected stores.`,
    },
    {
      question: `How much does Orders.co setup cost?`,
      answer: `Orders.co setup fee is $100.`,
    },
    {
      question: `How will orders from multiple virtual stores be displayed in Orders.co?`,
      answer: `All your integrated virtual stores are shown together in the Orders.co “Orders” list.
                Next to each order is the Brand Name and Platform, so staff can easily identify every order.`,
    },
    {
      question: `How can we integrate an Orders.co ordering site into our existing website?`,
      answer: `An Orders.co ordering site can be easily integrated into your existing website through
                the addition of an “Order Now” button. Customers can click this button and quickly be
                redirected to place their order.`,
    },
  ];

  return (
    <>
      <Seo
        title={"Ghost Kitchen"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <Features title="Top Benefits" items={features}></Features>
        <SingleDevice {...singleDeviceProps} />
        <Testimonials {...testimonialsProps}></Testimonials>
        <ImageText {...imageTextProps1} />
        <ImageText {...imageTextProps2} />
        <ImageText {...imageTextProps3} />
        <div
          className="bg-mobile"
          style={{
            background:
              "linear-gradient(180deg, #FFF8ED 0%, rgba(255, 248, 237, 0.5) 100%)",
          }}
        >
          <Integrations />
          <News items={data.allWpPost} />
          <Faq items={questions} />
          <div ref={formRef}>
            <ContactForm
              title={
                <>
                  Streamline Your
                  <br /> Ghost Kitchen
                </>
              }
            ></ContactForm>
          </div>
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "ghost-kitchen" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "ghost-kitchen"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default GhostKitchenPage;
